@import './gridVariables.scss';
@import './colorVariables.scss';
@import './fontsVariables.scss';
@import './mixin.scss';

$global-radius: 32px;
$small-footer-height: small-vw(512);
$tablet-footer-height: tablet-vw(503);
$smDesktop-footer-height: 255px;

$small-container-padding: 15px;
$medium-container-padding: 17px;
$large-container-padding: 20px;