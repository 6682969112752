@import url('https://fonts.googleapis.com/css?family=Roboto:400,500,700&display=swap');

@font-face {
  font-family: 'FordAntenna';
  font-display: swap;
  font-style: normal;
  font-weight: 400;
  src: url('../fonts/FordAntenna-Regular.eot'); /* IE9 Compat Modes */
  src: local('FordAntenna'), local('FordAntenna-Regular'),
  url('../fonts/FordAntenna-Regular.eot?#iefix') format('embedded-opentype'), /* IE6-IE8 */
  url('../fonts/FordAntenna-Regular.woff') format('woff'), /* Modern Browsers */
  url('../fonts/FordAntenna-Regular.ttf') format('truetype'), /* Safari, Android, iOS */
  url('../fonts/FordAntenna-Regular.svg') format('svg'); /* Legacy iOS */
}

@font-face {
  font-family: 'FordAntenna';
  font-display: swap;
  font-style: normal;
  font-weight: 600;
  src: url('../fonts/FordAntenna-Semibold.eot'); /* IE9 Compat Modes */
  src: local('FordAntenna'), local('FordAntenna-Semibold'),
  url('../fonts/FordAntenna-Semibold.eot?#iefix') format('embedded-opentype'), /* IE6-IE8 */
  url('../fonts/FordAntenna-Semibold.woff') format('woff'), /* Modern Browsers */
  url('../fonts/FordAntenna-Semibold.ttf') format('truetype'), /* Safari, Android, iOS */
}

@font-face {
  font-family: 'FordAntenna';
  font-display: swap;
  font-style: bold;
  font-weight: 900;
  src: url('../fonts/FordAntenna-Bold.eot'); /* IE9 Compat Modes */
  src: local('FordAntenna'), local('FordAntenna-Bold'),
  url('../fonts/FordAntenna-Bold.eot?#iefix') format('embedded-opentype'), /* IE6-IE8 */
  url('../fonts/FordAntenna-Bold.woff') format('woff'), /* Modern Browsers */
  url('../fonts/FordAntenna-Bold.ttf') format('truetype'), /* Safari, Android, iOS */
  url('../fonts/FordAntenna-Bold.svg') format('svg'); /* Legacy iOS */
}