body {
  margin: 0;
  padding: 0;
  font-family: sans-serif;
}

.article__full--body {
  width: 100%;

  img {
    width: 100%;
    height: auto;
    background-size: cover;
  }
}
