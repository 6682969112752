.cookie-banner {
  position: fixed;
  display: block;
  width: 100%;
  padding: 28px 0;
  background: #F5F5F5;
  font-size: 14px;
  color: #000000;
  z-index: 2147483647;
  border: 0 solid white;
  bottom: 0;
}

.cookie-banner__body {
  position: relative;
  width: 80%;
  max-width: 1440px;
  margin: 0 auto;
  display: flex;
  align-items: center;
  gap: 50px;
  z-index: 10000;
}

@media screen and (max-width: 850px) {
  .cookie-banner__body {
    display: block;
  }
}

.cookie-banner__background {
  display: block;
  position: fixed;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  background: #dddddd;
  opacity: 0.5;
  z-index: 1000;
}

.cookie-banner__title {
  display: block;
  margin: 0 0 20px;
  padding: 0;
  font-size: 18px;
  text-align: left;
  color: #000000;
  font-weight: normal;
}

.cookie-banner__message {
  display: block;
  margin: 0;
  padding: 0;
  color: #000000;
  font-size: 14px;
  text-align: left;
  line-height: 1.3;
}

.cookie-banner__buttons {
  margin: 0 0 0 50px;
}

@media screen and (max-width: 850px) {
  .cookie-banner__buttons {
    margin: 20px 0 0;
  }
}

.cookie-banner__agree {
  display: inline-block;
  border: 1px solid #E91C24;
  border-radius: 2px;
  background: #E91C24;
  border-radius: 15px;
  color: white;
  padding: 8px 22px;
  cursor: pointer;
}

.cookie-banner a {
  color: #E91C24;
}