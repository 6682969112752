$White: #ffffff;
$Merino: #FAF8F1;
$EcruWhite: #f5f1e5;
$WildSand: #F5F5F5;
$Alto: #DBDBDB;
$Grey: #CBCBCB;
$Chino: #D4CBB1;
$Empress: #777174;
$Masala: #4A4542;
$MineShaft: #393738;
$Black: #000000;
$FountainBlue: #45B8BA;
$Pelorous: #40afb1;
$EarlsGreen: #B5BB34;
$CannonPink: #9b548a;
$MerinoOnCannonPink: #caa6bd;
$CannonPinkDark: #884177;
$AlizarinCrimson: #ED1D24;
$partnerBg: #f6f1e6;
$eventsPopupBg: #9A5389;
$Orange: #FAA018;