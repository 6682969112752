@import '../../styles/variables/variables.scss';

.preloader {
  display: flex;
  align-items: center;
  justify-content: center;
  min-height: 200px;
}

@media (min-width: $mobileWidth) {
}

@media (min-width: $tabletWidth) {
  .preloader {
  }
}

@media (min-width: $smDesktopWidth) {
  .preloader {
  }
}


